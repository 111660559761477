<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{form.id ? '编辑产品分类' : '新增产品分类' }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="分类名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { goodsClassificationCreate, goodsClassificationUpdate } from '@/api/goods'

export default {
  name: 'FormModal',
  props: ['visible', 'form'],
  model: { prop: 'visible', event: 'cancel' },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入分类名称', trigger: 'change' },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },]
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? goodsClassificationUpdate : goodsClassificationCreate;
          func(this.form).then(data => {
            this.$message.success(this.form.id ? '修改成功' : '新增成功');
            this.$emit(this.form.id ? 'update' : 'create', data);
            this.cancel();
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    cancel() {
      this.$emit('cancel', false);
      this.$refs.form.resetFields();
    },
  },
}
</script>

<style scoped>
</style>